<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="CHM_DANGER_TYPE_CD"
            label="구분"
            itemText="codeName"
            itemValue="code"
            name="chmDangerTypeCd"
            type="search"
            v-model="searchParam.chmDangerTypeCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 점검기간 -->
          <c-datepicker
            :range="true"
            name="period"
            label="점검 기간"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="CHM_STATUS_DANGER_REGULAR_CHECK_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="chmStatusDangerRegularCheckCd"
            label="진행상태"
            v-model="searchParam.chmStatusDangerRegularCheckCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="위험물 정기점검 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="onItemClick" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "dangerRegularCheck",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:60px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerTypeName",
            field: "chmDangerTypeName",
            label: "구분",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerMstName",
            field: "chmDangerMstName",
            label: "위험물 저장소",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerCheckName",
            field: "chmDangerCheckName",
            label: "점검명",
            style: 'width:200px',
            align: "left",
            type: "link",
            sortable: true,
          },
          {
            name: "chmDangerDate",
            field: "chmDangerDate",
            style: 'width:100px',
            label: "점검 기간",
            align: "center",
            sortable: true,
          },
          {
            name: 'chmStatusDangerRegularCheckName',
            field: 'chmStatusDangerRegularCheckName',
            // 진행단계
            label: 'LBLPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: "chmDangerInspectorUserName",
            field: "chmDangerInspectorUserName",
            style: 'width:150px',
            label: "점검자",
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        chmDangerTypeCd: null,
        plantCd: null,
        period: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.danger.regularChecks.list.url;
      // code setting
      // list setting
      this.getList()
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick();
    },
    linkClick(row) {
      this.popupOptions.title = "위험물정기점검 상세"; // 위험물저장소 상세
      this.popupOptions.param = {
        chmDangerRegularCheckId: row ? row.chmDangerRegularCheckId : '',
      };
      this.popupOptions.target = () => import(`${"./dangerRegularCheckDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
